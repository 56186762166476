html,
body {
  margin: 0;
  padding: 0;
  font-family:
    Geist,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Oxygen,
    Ubuntu,
    Cantarell,
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  box-sizing: border-box;
  font-size: 16px;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul,
figure {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

button {
  background: none;
  border: none;
  cursor: pointer;
}

ol,
ul {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}

dl,
dt,
dd {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

[type='search']::-webkit-search-decoration,
[type='search']::-webkit-search-cancel-button {
  position: relative;
  width: 15px;
  height: 15px;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'  fill='%23fe3b1f'><path d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'/></svg>");
  opacity: 1;
  appearance: none;
}

/* Best buy ad */
#simpli-vplacement-,
#simpli-vplacement-* {
  display: none;
}

.tp-modal > .tp-iframe-wrapper > .tp-close {
  top: 15px;
  right: 15px;
  height: 20px;
  width: 20px;
  margin: 0;
  background: transparent
    url('https://s3.us-west-2.amazonaws.com/on3static.com/static/on3/close-button.svg')
    center center;
  background-size: cover;
  border-radius: 0;
  box-shadow: none;
}

.jw-float-to-top {
  top: 0 !important;
}

@media only screen and (min-width: 768px) {
  .jw-flag-floating > .jw-wrapper {
    top: auto !important;
    bottom: 48px !important;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

/* Scroll Blocker for Article Barrier */
.blocker-active {
  overflow: hidden !important;
}

.infinite-scroll-component {
  -webkit-overflow-scrolling: unset !important;
}
